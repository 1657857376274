import React from "react"
import Seo from "../components/SEO"
import Banner from "../components/Banner"
import Layout from "../components/Layout"

const PrivacyPage = () => {
  return (
    <>
      <Seo />
      <Layout>
        <Banner content="Read the Updated Privacy Policy Below" />
        <p>
        <b>Changes to this Privacy Policy</b>
        </p>
        <p>
        We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on the Service. If required by law we will also provide notification of changes in another way that we believe is reasonably likely to reach you, such as via email or another manner through the Service. Any modifications to this Privacy Policy will be effective upon our posting the modified version (or as otherwise indicated at the time of posting). In all cases, your use of the Service after the effective date of any modified Privacy Policy indicates your acceptance of the modified Privacy Policy.
        </p>
        
        {/* <p>
        <b>Your California Privacy Rights</b>
        </p>
        <p>
        Under California Civil Code Section 1798.83, California residents are entitled to ask us for a notice identifying the categories of personal customer information which we share with our affiliates and/or third parties for marketing purposes, and providing contact information for such affiliates and/or third parties.
        If you are a California resident and would like a copy of this notice, please submit a written request to us via mail to the address list in the link below. 
        You must put the statement “Your California Privacy Rights” in your request and include your name, street address, city, state, and ZIP code. We are not responsible for notices that are not labeled or sent properly, or do not have complete information.
        </p> */}
        <a href="https://www.iubenda.com/privacy-policy/61437531" class="iubenda-black no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Privacy Policy">Privacy Policy</a>
      </Layout>
    </>
  )
}

export default PrivacyPage
